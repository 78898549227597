@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-BookItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-RegularItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-SemiBoldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-ThinItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-UltraLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("./assets/fonts/SuisseIntl-UltraLightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

body {
  margin: 0;
  font-family:
    /* "Outfit", */
    "SuisseIntl",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hover-stroke-white:hover > svg {
  stroke: white;
}

.related-question:hover {
  background-color: #f3f4f6c4;
}

.central-font {
  font-family: Suisse, Helvetica, sans-serif;
}

.central-sub-font {
  font-family: Suisse, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.central-font-bold {
  font-family: Suisse, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
}
